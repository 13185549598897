// check ecmascript 6
function es6_check() {
	"use strict";

	try { eval("var foo = (x)=>x+1"); }
	catch (e) {
		alert("Il browser in uso non è supportato.\n\
\n\
Di seguito i browser supportati:\n\
    - Chrome versione 51 e successive\n\
    - Firefox versione 54 e successive\n\
    - Edge versione 14 e successive\n\
    - Safari versione 10 e successive");
		return false;
	}
	return true;
}
es6_check();

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//vs
import 'jquery'
import('src/plugins')
import('src/colloquioremoto')
import 'css/site'

const images = require.context('../images', true)